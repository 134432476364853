import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Level, programs, Stage, toastConfigs } from "../constants";
import { toast } from "react-toastify";

function StudentLogin() {
  const navigate = useNavigate();
  // const savedProgramId = localStorage.getItem('selectedProgramId');
  // const selectedProgram = savedProgramId
  //   ? programs.filter((program) => program.id === +savedProgramId)[0]
  //   : null;
  const selectedProgramId = 1;
  const selectedProgram = programs.find(
    (program) => program.id === selectedProgramId
  );
  const [selectedLevel, setSelectedLevel] = useState<Level | null>(null);
  const [selectedStage, setSelectedStage] = useState<Stage | null>(null);
  const [useEmail, setUseEmail] = useState(true);
  const [inputValue, setInputValue] = useState("");

  // useEffect(() => {
  //   if (!savedProgramId) {
  //   navigate("/");
  //   }
  // }, [savedProgramId, navigate]);

  const handleToggle = () => {
    setUseEmail(!useEmail);
    setInputValue(""); // Reset the input value when toggling
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const getGrades = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/student/get-grades`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            program: `${selectedProgram?.id}`,
            level: `${selectedLevel?.id}`,
            stage: `${selectedStage?.id}`,
            identifier: inputValue,
          }),
        }
      );
      if (!response.ok) {
        const body = await response.json();
        if (body?.message) {
          throw new Error(body.message);
        }
        throw new Error("لقد حدث خطأ");
      }
      const result = await response.json();
      // Route to the grades page with state using react router
      navigate("/student/grades", {
        state: {
          gradesData: result,
          programName: selectedProgram?.title,
          levelName: selectedLevel?.name,
          stageName: selectedStage?.name,
        },
      });
    } catch (error: any) {
      toast.error(error.message);
      console.error("Error:", error);
    }
  };

  const handleLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (selectedProgram) {
      const selectedLevelId = parseInt(e.target.value);
      const level =
        selectedProgram.levels.find((level) => level.id === selectedLevelId) ||
        null;
      setSelectedLevel(level);
      setSelectedStage(null);
    }
  };

  const handleStageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (selectedProgram && selectedLevel) {
      const selectedStageId = parseInt(e.target.value);
      const stage =
        selectedProgram.levels
          .find((level) => level.id === selectedLevel.id)
          ?.stages?.find((stage) => stage.id === selectedStageId) || null;
      setSelectedStage(stage);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedProgram) {
      getGrades();
    } else {
      toast.error("الرجاء تحديد البرنامج اولا", toastConfigs);
      navigate("/");
    }
  };

  return (
    <div className="bg-gray-100 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-sm bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold text-center mb-6">تسجيل الدخول</h2>
        <div className="overflow-hidden">
          <img
            src={selectedProgram?.image}
            alt={selectedProgram?.title}
            className="object-cover w-full h-full hover:opacity-80 m-auto"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="level"
              className="block text-gray-700 font-medium mb-2"
            >
              اختر المستوى
            </label>
            <select
              id="level"
              value={selectedLevel?.id || ""}
              onChange={handleLevelChange}
              className="w-1/2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            >
              <option value="">اختر المستوى</option>
              {selectedProgram?.levels.map((level) => (
                <option key={level.id} value={level.id}>
                  {level.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="level"
              className="block text-gray-700 font-medium mb-2"
            >
              اختر المرحلة
            </label>
            <select
              id="level"
              value={selectedStage?.id || ""}
              onChange={handleStageChange}
              className="w-1/2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            >
              <option value="">اختر المرحلة</option>
              {selectedLevel?.stages?.map((stage) => (
                <option key={`stage-${stage?.id}`} value={stage.id}>
                  {stage.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center justify-between mb-4">
            <label className="inline-flex relative items-center cursor-pointer">
              <span className="ml-4 text-gray-700 font-medium">
                {useEmail
                  ? "استخدم الرقم الأكاديمي"
                  : "استخدم البريد الإلكتروني"}
              </span>
              <input
                type="checkbox"
                checked={!useEmail}
                onChange={handleToggle}
                className="sr-only peer"
              />
              <div className='w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:bg-blue-600 after:content-[""] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white'></div>
            </label>
          </div>
          <div className="mb-4">
            <label
              htmlFor="input"
              className="block text-gray-700 font-medium mb-2"
            >
              {useEmail ? "البريد الإلكتروني" : "الرقم الأكاديمي"}
            </label>
            <input
              type={useEmail ? "email" : "text"}
              id="input"
              value={inputValue}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-medium py-2 rounded hover:bg-blue-600 focus:outline-none"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default StudentLogin;
