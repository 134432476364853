import { ToastOptions } from "react-toastify";

export interface Stage {
  id: number;
  name: string;
}

export interface Level {
  id: number;
  name: string;
  stages?: Stage[];
}

export interface Program {
  id: number;
  title: string;
  image: string;
  levels: Level[];
}

export const programs: Program[] = [
  {
    "title": "برنامج البناء المنهجي",
    // "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F6.png&w=3840&q=75",
    "image": "https://freecoursesandbooks.net/wp-content/uploads/2021/08/%D8%A7%D9%84%D8%A8%D9%86%D8%A7%D8%A1-%D8%A7%D9%84%D9%85%D9%86%D9%87%D8%AC%D9%8A.png",
    id: 1,
    levels: [
      {
        id: 1,
        name: "المستوى الأول",
        stages: [{
          id: 1,
          name: "المرحلة الأولى"
        }, {
          id: 2,
          name: "المرحلة الثانية"
        }, {
          id: 3,
          name: "المرحلة الثالثة"
        }, {
          id: 4,
          name: "المرحلة الرابعة"
        }]
      },
      {
        id: 2,
        name: "المستوى الثاني",
        stages: [{
          id: 1,
          name: "المرحلة الأولى"
        }, {
          id: 2,
          name: "المرحلة الثانية"
        }, {
          id: 3,
          name: "المرحلة الثالثة"
        }, {
          id: 4,
          name: "المرحلة الرابعة"
        }]
      },
      {
        id: 3,
        name: "المستوى الثالث",
        stages: [{
          id: 1,
          name: "المرحلة الأولى"
        }, {
          id: 2,
          name: "المرحلة الثانية"
        }, {
          id: 3,
          name: "المرحلة الثالثة"
        }, {
          id: 4,
          name: "المرحلة الرابعة"
        }]
      },
    ],
  },
  {
    "title": "أكاديمية الجيل الصاعد",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F1.png&w=3840&q=75",
    id: 2,
    levels: [
      { id: 1, name: "Level 11" },
      { id: 2, name: "Level 12" },
      { id: 3, name: "Level 13" },
    ],
  },
  {
    "title": "برنامج برد اليقين",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F15.png&w=3840&q=75",
    id: 3,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  },
  {
    "title": "برنامج البناء الفكري",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F7.png&w=3840&q=75",
    id: 4,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  },
  {
    "title": "برنامج مشروع العمر للمصلحين",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F3.png&w=3840&q=75",
    id: 5,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  },
  {
    "title": "برنامج عالم",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F12.png&w=3840&q=75",
    id: 6,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  },
  {
    "title": "أكاديمية الحديث الإلكترونية",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F2.png&w=3840&q=75",
    id: 7,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  },
  {
    "title": "برنامج صناعة المحاور",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F4.png&w=3840&q=75",
    id: 8,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  },
  {
    "title": "موقع المحاورون",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F5.png&w=3840&q=75",
    id: 9,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  },
  {
    "title": "برنامج مدرسة خديجة",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F9.png&w=3840&q=75",
    id: 10,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  },
  {
    "title": "برامج الجيل الصاعد",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F10.png&w=3840&q=75",
    id: 11,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  },
  {
    "title": "برنامج السَّراة",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F11.png&w=3840&q=75",
    id: 12,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  },
  {
    "title": "برنامج أفق",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F13.png&w=3840&q=75",
    id: 13,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  },
  {
    "title": "برنامج الرواد",
    "image": "https://ahmadalsayed.net/_next/image?url=%2Fprograms%2Fprogram_covers%2F14.png&w=3840&q=75",
    id: 14,
    levels: [
      { id: 1, name: "Level 1" },
      { id: 2, name: "Level 2" },
      { id: 3, name: "Level 3" },
    ],
  }
];

export const toastConfigs: ToastOptions = {
  position: "top-right"
}