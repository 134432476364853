import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function StudentGrades() {
  const navigate = useNavigate();
  const location: {
    state: {
      gradesData: {
        grades: {
          student: {
            email: string;
            name: string;
            academicNumber: string;
          };
          percentage: number;
          grade: string;
          testName: string;
        }[];
        telegramUrl: string;
      };
      programName: string;
      levelName: string;
      stageName: string;
    };
  } = useLocation();
  
  
  // Check if location.state and gradesData exist before destructuring
  const { gradesData, programName, levelName, stageName } = location.state;
  const { grades, telegramUrl } = gradesData || { grades: [], telegramUrl: "" };

  useEffect(() => {
    if (!gradesData) {
    navigate("/");
    }
  }, [gradesData, navigate]);


  return (
    <div className="bg-gray-100 p-8 min-h-screen">
      <h1 className="text-center text-3xl mb-4">درجات برنامج {programName}</h1>
      <h2 className="text-center text-gray-800 text-2xl my-4">{levelName}</h2>
      <h2 className="text-center text-xl text-gray-600 my-4">{stageName}</h2>
      <hr className="my-4 border-t border-gray-300" />
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border rounded-lg shadow-md">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="py-3 px-4 border-b">الأسم</th>
              <th className="py-3 px-4 border-b">الرقم الأكاديمي</th>
              <th className="py-3 px-4 border-b">البريد الإلكتروني</th>
              <th className="py-3 px-4 border-b">الأختبار</th>
              <th className="py-3 px-4 border-b">النسبة</th>
              <th className="py-3 px-4 border-b">الدرجة</th>
            </tr>
          </thead>
          <tbody>
            {grades.map((grade, index) => (
              <tr key={index} className="text-center">
                <td className="py-3 px-4 border-b">{grade?.student?.name}</td>
                <td className="py-3 px-4 border-b">
                  {grade?.student.academicNumber}
                </td>
                <td className="py-3 px-4 border-b">{grade?.student?.email}</td>
                <td className="py-3 px-4 border-b">{grade?.testName}</td>
                <td className="py-3 px-4 border-b">{grade.percentage}%</td>
                <td className="py-3 px-4 border-b">{grade.grade}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {telegramUrl && (
        <div className="mt-6 p-4 bg-white rounded-lg shadow-md">
          <p className="text-gray-700 font-medium">
            تهانينا لجميع الطلاب على اجتياز الدورة بنجاح! نرحب بكم للانضمام إلى
            المجموعة الخاصة بالدورة القادمة على تليجرام.
          </p>
          <a
            href={telegramUrl}
            className="block mt-4 text-blue-500 hover:underline text-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            انقر هنا للانضمام إلى المجموعة
          </a>
        </div>
      )}
    </div>
  );
}

export default StudentGrades;
