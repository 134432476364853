import React, { useCallback, useEffect, useRef, useState } from "react";
import { Program, programs, Level, Stage } from "../constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import fetchWithAuth from "../fetchWithAuth";

function AdminConfigs() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [selectedProgram, setSelectedProgram] = useState<Program | undefined>(
    programs.find((program) => program.id === 1)
  );
  const [selectedLevel, setSelectedLevel] = useState<Level | null>(null);
  const [selectedStage, setSelectedStage] = useState<Stage | null>(null);
  const [config, setConfig] = useState<any>(null);
  const [gradesFile, setGradesFile] = useState<File | null>(null);
  const [showGrades, setShowGrades] = useState<boolean>(true);
  const [testName, setTestName] = useState<string>("");
  const [maleTelegramUrl, setMaleTelegramUrl] = useState<string>("");
  const [femaleTelegramUrl, setFemalTelegramLink] = useState<string>("");
  const [showTelegramLink, setShowTelegramLink] = useState<boolean>(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null); // Ref for file input

  useEffect(() => {
    if (!token) {
      navigate("/admin/login");
    }
  }, [token, navigate]);

  const uploadGrades = async () => {
    try {
      if (!gradesFile) {
        toast.error("الرجاء اختيار ملف الدرجات");
        return;
      }

      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", gradesFile as Blob);
      formData.append("program", `${selectedProgram?.id}`);
      formData.append("level", `${selectedLevel?.id}`);
      formData.append("stage", `${selectedStage?.id}`);
      formData.append("testName", testName);
      const result = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/admin/upload-grades`,
        {
          method: "POST",
          body: formData,
        }
      );
      toast.success(result.message);
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error.message);
    } finally {
      setIsUploading(false);
      setGradesFile(null); // Reset grades file state
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset file input value
      }
    }
  };

  const saveConfigs = async () => {
    try {
      if (gradesFile) {
        await uploadGrades();
      }
      const { message } = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/admin/update-config`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            program: `${selectedProgram?.id}`,
            level: `${selectedLevel?.id}`,
            stage: `${selectedStage?.id}`,
            showGrades,
            maleTelegramUrl,
            femaleTelegramUrl,
            showTelegram: showTelegramLink,
          }),
        }
      );
      toast.success(message);
      setIsSaved(true);
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  const getConfig = useCallback(async () => {
    try {
      const { config } = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/admin/get-config`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            program: `${selectedProgram?.id}`,
            level: `${selectedLevel?.id}`,
            stage: `${selectedStage?.id}`,
          }),
        }
      );
      setConfig(config);
    } catch (error: any) {
      console.error("Error:", error);
      setConfig(null);
    }
  }, [selectedStage?.id, selectedLevel?.id, selectedProgram?.id]);

  useEffect(() => {
    if (selectedStage?.id) {
      getConfig();
    }
  }, [selectedStage?.id, getConfig]);

  useEffect(() => {
    if (isSaved) {
      setGradesFile(null);
      setIsSaved(false);
      getConfig();
    }
  }, [isSaved, getConfig]);

  useEffect(() => {
    if (config) {
      setShowGrades(config.showGrades);
      setMaleTelegramUrl(config.maleTelegramUrl);
      setFemalTelegramLink(config.femaleTelegramUrl);
      setShowTelegramLink(config.showTelegram);
    } else {
      setShowGrades(true);
      setMaleTelegramUrl("");
      setFemalTelegramLink("");
      setShowTelegramLink(true);
    }
  }, [config]);

  const handleProgramChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProgramId = parseInt(e.target.value);
    const program = programs.find(
      (program) => program.id === selectedProgramId
    );
    setSelectedProgram(program);
    setSelectedLevel(null);
  };

  const handleLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (selectedProgram) {
      const selectedLevelId = parseInt(e.target.value);
      const level =
        selectedProgram.levels.find((level) => level.id === selectedLevelId) ||
        null;
      setSelectedLevel(level);
      setSelectedStage(null);
    }
  };

  const handleStageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (selectedProgram && selectedLevel) {
      const selectedStageId = parseInt(e.target.value);
      const stage =
        selectedProgram.levels
          .find((level) => level.id === selectedLevel.id)
          ?.stages?.find((stage) => stage.id === selectedStageId) || null;
      setSelectedStage(stage);
      setTestName("");
    }
  };

  const handleTestNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTestName(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setGradesFile(e.target.files[0]);
    }
  };

  const handleGradesToggle = () => {
    setShowGrades(!showGrades);
  };

  const handleMaleTelegramUrlChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaleTelegramUrl(e.target.value);
  };

  const handleFemaleTelegramUrlChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFemalTelegramLink(e.target.value);
  };

  const handleTelegramToggle = () => {
    setShowTelegramLink(!showTelegramLink);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveConfigs();
  };

  return (
    <div className="bg-gray-100 p-8 min-h-screen">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center">إعدادات الإدارة</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="flex items-center">
            <div className="w-3/4">
              <div className="mb-4">
                <label
                  htmlFor="program"
                  className="block text-gray-700 font-medium mb-2"
                >
                  اختر البرنامج
                </label>
                <select
                  id="program"
                  value={selectedProgram?.id || ""}
                  onChange={handleProgramChange}
                  className="w-1/2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                >
                  {/* {programs.map((program) => ( */}
                  {[selectedProgram].map((program) => (
                    <option key={`program-${program?.id}`} value={program?.id}>
                      {program?.title}
                    </option>
                  ))}
                </select>
              </div>
              {selectedProgram && (
                <div className="mb-4">
                  <label
                    htmlFor="level"
                    className="block text-gray-700 font-medium mb-2"
                  >
                    اختر المستوى
                  </label>
                  <select
                    id="level"
                    value={selectedLevel?.id || ""}
                    onChange={handleLevelChange}
                    className="w-1/2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                  >
                    <option value="">اختر المستوى</option>
                    {selectedProgram.levels.map((level) => (
                      <option key={`level-${level?.id}`} value={level.id}>
                        {level.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {selectedProgram && selectedLevel && (
                <div className="mb-4">
                  <label
                    htmlFor="level"
                    className="block text-gray-700 font-medium mb-2"
                  >
                    اختر المرحلة
                  </label>
                  <select
                    id="level"
                    value={selectedStage?.id || ""}
                    onChange={handleStageChange}
                    className="w-1/2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                  >
                    <option value="">اختر المرحلة</option>
                    {selectedLevel?.stages?.map((stage) => (
                      <option key={`stage-${stage?.id}`} value={stage.id}>
                        {stage.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {selectedProgram && selectedLevel && selectedStage && (
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-gray-700 font-medium mb-2"
                  >
                    أسم الاختبار
                  </label>
                  <input
                    value={testName}
                    onChange={handleTestNameChange}
                    type="text"
                    id="test-name"
                    name="test-name"
                    required
                    className="w-1/2 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                  />
                </div>
              )}
            </div>
            {selectedProgram && (
              <div>
                <img
                  src={selectedProgram.image}
                  alt={selectedProgram.title}
                  className="w-64 mb-2 hover:opacity-50"
                />
              </div>
            )}
          </div>
          {selectedProgram && selectedLevel && selectedStage && (
            <>
              <div className="mb-4 w-full">
                <label
                  htmlFor="gradesFile"
                  className="block text-gray-700 font-medium mb-2"
                >
                  تحميل درجات الطلاب (CSV أو Excel)
                </label>
                <input
                  type="file"
                  id="gradesFile"
                  accept=".csv, .xlsx"
                  onChange={handleFileChange}
                  className="w-full"
                  disabled={isUploading}
                  ref={fileInputRef} // Attach ref to input
                />
              </div>
              <div className="mb-8 flex items-center justify-start">
                <span className="mx-4 text-gray-700 font-medium">
                  عرض الدرجات للطلاب
                </span>
                <label className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={showGrades}
                    onChange={handleGradesToggle}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:bg-blue-600 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
                </label>
              </div>
              <div className="mb-8">
                <label className="block text-gray-700 font-medium mb-2">
                  روابط مجموعة الدورة الجديدة على تليجرام
                </label>

                {/* Male Telegram Link Input */}
                <div className="flex items-center border border-gray-300 rounded mb-2 focus-within:border-blue-500">
                  <div className="px-3 py-2 flex items-center bg-gray-200 rounded-l">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-blue-500"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                    </svg>
                  </div>
                  <input
                    type="url"
                    id="maleTelegramUrl"
                    value={maleTelegramUrl}
                    onChange={handleMaleTelegramUrlChange}
                    className="w-full px-3 focus:outline-none rounded-r"
                    placeholder="https://t.me/male_group_link"
                  />
                </div>

                {/* Female Telegram Link Input */}
                <div className="flex items-center border border-gray-300 rounded focus-within:border-pink-500">
                  <div className="px-3 py-2 flex items-center bg-gray-200 rounded-l">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-pink-500"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 2v-2h-2v2c0 2.66-5.33 4-8 4-2.67 0-8-1.34-8-4v-2H0v2c0 3.87 5.12 6 10 6s10-2.13 10-6z" />
                    </svg>
                  </div>
                  <input
                    type="url"
                    id="femaleTelegramUrl"
                    value={femaleTelegramUrl}
                    onChange={handleFemaleTelegramUrlChange}
                    className="w-full px-3 focus:outline-none rounded-r"
                    placeholder="https://t.me/female_group_link"
                  />
                </div>
              </div>

              <div className="mb-6 flex items-center justify-start">
                <span className="text-gray-700 font-medium">
                  عرض رابط تليجرام للطلاب
                </span>
                <label className="mx-4 inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={showTelegramLink}
                    onChange={handleTelegramToggle}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:bg-blue-600 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
                </label>
              </div>
            </>
          )}
          <button
            type="submit"
            className="w-full mb-4 bg-blue-500 text-white font-medium py-2 rounded hover:bg-blue-600 focus:outline-none flex items-center justify-center disabled:opacity-50"
            disabled={isUploading || !selectedProgram || !selectedLevel || !selectedStage}
          >
            {isUploading ? (
              <div className="flex gap-x-4">
                <div className="loader"></div>
                <p>جاري حفظ البيانات</p>
              </div>
            ) : (
              "حفظ الإعدادات"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminConfigs;
