const fetchWithAuth = async (url: string, options: RequestInit = {}) => {
  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        ...options.headers
      }
    });

    if (response.status === 401) {
      // If you receive a 401, clear the JWT from localStorage
      localStorage.removeItem('token');

      // Optionally, redirect to the login page
      window.location.href = '/admin/login';
    } else if (!response.ok) {
      // Handle other errors
      throw new Error('لقد حدث خطأ');
    }

    // If everything is fine, return the response data
    return await response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

export default fetchWithAuth;
