import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import AdminLogin from './login/AdminLogin';
import StudentLogin from './login/StudentLogin';
import StudentGrades from './StudentGrades';
import AdminConfigs from './admin-configs/AdminConfigs';

import 'react-toastify/dist/ReactToastify.css';

const router = createBrowserRouter([
  {
    path: "/",
    // element: <App />,
    element: <Navigate to="/student/login" replace />,
  },
  {
    path: "/student/login",
    element: <StudentLogin />,
  },
  {
    path: "/admin/login",
    element: <AdminLogin />,
  },
  {
    path: "/admin/configs",
    element: <AdminConfigs />,
  },
  {
    path: "/student/grades",
    element: <StudentGrades />,
  },
  {
    path: "/admin",
    element: <Navigate to="/admin/login" replace />,
  },
  {
    path: "/student",
    element: <Navigate to="/student/login" replace />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <>
    <RouterProvider router={router} />
    <ToastContainer />
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
